.registration-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  backdrop-filter: blur(5px);
}

.registration-form {
  width: 100%;
  max-width: 500px;
  padding: 32px;
  border-radius: 28px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 51;

  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h1 {
      font-family: "Inter", sans-serif;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.025em;
    }

    .close-button {
      background: none;
      border: none;
      font-size: 28px;
      cursor: pointer;
      color: #666;
      padding: 4px 12px;
      border-radius: 50%;
      transition: 0.2s all;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .form-group {
    margin-bottom: 16px;
  }

  .field {
    width: 90%;
    padding: 12px 16px;
    border-radius: 12px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    outline: none;
    border: 2px solid transparent;
    transition: 0.2s all;

    &:focus {
      border-color: #0064ff;
    }
  }

  .submit {
    width: 100%;
    padding: 14px;
    background-color: #0064ff;
    color: white;
    border: none;
    border-radius: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      background-color: #004fc5;
    }
  }
}

// Dark mode styles
.dark-field {
  background-color: #1d1d1f;
  border-color: hsla(0, 0%, 51.4%, 0.16);
  color: white;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}

.light-field {
  background-color: #f5f5f7;
  border-color: #ebebeb;
  color: #1d1d1f;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}
