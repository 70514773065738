* {
    margin: 0;
    padding: 0;
}

nav {
    display: flex;
    z-index: 40;
    position: fixed;
    top: 1.235em;
    border-radius: 38px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    align-items: center;
    height: 51px;
    padding: 4px 8px;
    backdrop-filter: blur(10px);
    box-sizing: border-box;

    @media screen and (max-width: 500px) {
        height: 40px;
    }

    @media screen and (max-width: 386px) {
        height: 34px;
        padding: 4px 5px;
    }

    img {
        border-radius: 9999px;
        border: none;
        cursor: pointer;
        height: 37px;
        width: 37px;
        margin-right: 22px;
        transition: 0.2s ease-in-out all;

        &:hover {
            scale: 1.05;
        }

        @media screen and (max-width: 500px) {
            height: 24px;
            width: 24px;
            margin-right: 14px;
        }

        @media screen and (max-width: 386px) {
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }
    }

    h3,
    button {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 89px;
        transition: 0.15s all ease-in-out;

        @media screen and (max-width: 598px) {
            width: 68px;
        }

        @media screen and (max-width: 500px) {
            width: 54px;
        }

        @media screen and (max-width: 386px) {
            width: 40px;
        }
    }

    .first {
        margin-right: 14px;
    }

    h3 {
        font-size: 16px;
        padding: 11px 0px 10px 0px;

        @media screen and (max-width: 598px) {
            font-size: 14px;
        }

        @media screen and (max-width: 500px) {
            font-size: 11px;
        }

        @media screen and (max-width: 386px) {
            font-size: 9px;
        }
    }

    .first {
        @media screen and (max-width: 386px) {
            width: 44px;
        }
    }

    button {
        margin-left: 24px;
        font-size: 15px;
        color: white;
        background-color: #0064FF;
        border: none;
        border-radius: 20px;
        padding: 7px 18px;

        @media screen and (max-width: 598px) {
            font-size: 14px;
            width: 76px;
        }

        @media screen and (max-width: 500px) {
            font-size: 11px;
            width: 58px;
            margin-left: 14px;
            padding: 4px 0px;
        }

        @media screen and (max-width: 386px) {
            font-size: 9px;
            width: 50px;
            margin-left: 10px;
            margin-top: 1px;
        }

        &:hover {
            background-color: #0041a3;
        }
    }
}