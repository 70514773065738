.App {
  display: flex;
  align-items: center;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
a,
img,
p,
div {

  &:hover {
    cursor: none;
  }
}

// Color mode settings

// light mode

.light-bg-1 {
  background-color: white;
  transition: 0.2s background-color;
}

.light-bg-2 {
  background-color: #f5f5f7;
  transition: 0.2s background-color;
}

.light-eyebrow {
  color: #535355;
  transition: 0.2s color;
}

.light-heading {
  color: #1d1d1f;
  transition: 0.2s color;
}

.light-phrase {
  color: #333333bf;
  transition: 0.2s color;
}

.light-nav {
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.2s background-color;
}

.light-anchor {
  color: #333333;

  &:hover {
    color: #0064FF;
    font-size: 18px;
  }
}

.light-card {
  border: 1px solid #ebebeb;
  background-color: white;
  transition: 0.2s border, 0.2s background-color;
}

.light-field {
  background-color: #f5f5f7;
  border: 2px solid #ebebeb;
  color: #5f5f5f;
  transition: 0.2s background-color, 0.2s border, 0.2s color;
}

.light-shadow {
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
  transition: 0.2s box-shadow;
}

.light-language-button {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.2);
  background-color: white;
  color: #1d1d1f;
  border: none;
  transition: 0.2s background-color, 0.2s box-shadow, 0.2s color, 0.2s border
}

.light-context-menu {
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.2s background-color;
}

.light-theme-button {
  color: #1d1d1f;
  background-color: white;
  border: none;
  transition: 0.3s all;
  font-size: 14px;

  &:hover {
    background-color: #0064FF;

    h4 {
      color: white;
    }

    .dark-icon {
      fill: white;
    }
  }
}

// dark mode

.dark-theme-button {
  border: 2px solid hsla(0,0%,51.4%,.16);
  background-color: #141414;
  color: white;
  font-size: 14px;
  transition: 0.3s background-color, 0.2s color, 0.2s border;

  &:hover {
    background-color: #0064FF;
  }
}

.dark-context-menu {
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.2s background-color;
}

.dark-card {
  border: 1px solid hsla(0,0%,51.4%,.16);
  background-color: #1d1d1f;
  transition: 0.2s background-color, 0.2s border
}

.dark-bg-1 {
  background-color: #1d1d1f !important;
  transition: 0.2s background-color;
}

.dark-bg-2 {
  background-color: black;
  transition: 0.2s background-color;
}

.dark-heading {
  color: white;
  transition: 0.2s color;
}

.dark-eyebrow {
  color: #f5f5f7c4;
  transition: 0.2s color;
}

.white {
  color: white;
  transition: 0.2s color;
}

.white-opacity {
  color: rgb(207, 207, 207);
  transition: 0.2s color;
}

.white-opacity-max {
  color: rgb(177, 177, 177);
  transition: 0.2s color;
}

.dark-nav {
  background-color: rgba(255, 255, 255, 0.151);
  transition: 0.2s background-color;
}

.dark-anchor {
  color: white;
  transition: 0.2s color, 0.2s font-size;

  &:hover {
    color: #30a2ff;
    font-size: 18px;
  }
}

.dark-expand {
  color: #30a2ff;
  transition: 0.2s color;
}

.dark-field {
  background-color: rgba(0, 0, 0, 0.35);
  border: 2px solid hsla(0,0%,51.4%,.16);
  color: rgba(255, 255, 255, 0.8);
  transition: 0.2s background-color, 0.2s border, 0.2s color;
}

.dark-shadow {
  box-shadow: 0px 3px 14px rgba(255, 255, 255, 0.2) !important;
  transition: 0.2s box-shadow;
}

.dark-language-button {
  border: 2px solid hsla(0,0%,51.4%,.16);
  background-color: #141414;
  color: white;
  transition: 0.2s background-color, 0.2s color, 0.2s border;
}

// media queries

@media only screen and (max-width: 598px) {
  .light-anchor,
  .dark-anchor {
    &:hover {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .light-anchor,
  .dark-anchor {
    &:hover {
      font-size: 11px;
    }
  }
}

@media only screen and (max-width: 386px) {
  .light-anchor,
  .dark-anchor {
    &:hover {
      font-size: 9px;
    }
  }
}