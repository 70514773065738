.container-aboutme {
  position: relative;
  padding-bottom: 140px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .start {
    align-self: flex-start;
  }
}

#about-me {
  position: absolute;
  top: 60px;
}

.eyebrow {
  padding-top: 143px;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.025em;
  font-family: "Inter", sans-serif;
  margin-bottom: 12px;
  margin-left: 24.475%;

  @media screen and (max-width: 1300px) {
    margin-left: 17.5%;
  }

  @media screen and (max-width: 1050px) {
    margin-left: 13.5%;
  }

  @media screen and (max-width: 586px) {
    margin-left: 9.25%;
  }

  @media screen and (max-width: 586px) {
    font-size: 24px;
  }
}

.heading {
  font-size: 92px;
  font-family: "Inter", sans-serif;
  max-width: 750px;
  letter-spacing: -0.045em;
  line-height: 1.1;
  margin-left: 24.3%;
  font-weight: 600;

  @media screen and (max-width: 1300px) {
    margin-left: 17.35%;
  }

  @media screen and (max-width: 1050px) {
    margin-left: 13.35%;
  }

  @media screen and (max-width: 860px) {
    font-size: 82px;
  }

  @media screen and (max-width: 770px) {
    font-size: 72px;
    max-width: 560px;
  }

  @media screen and (max-width: 770px) {
    font-size: 62px;
    max-width: 500px;
  }

  @media screen and (max-width: 586px) {
    margin-left: 9%;
    max-width: 485px;
  }

  @media screen and (max-width: 586px) {
    font-size: 50px;
  }

  @media screen and (max-width: 428px) {
    font-size: 44px;
    max-width: 340px;
  }

  @media screen and (max-width: 380px) {
    font-size: 40px;
  }

  @media screen and (max-width: 340px) {
    max-width: 280px;
  }
}

.success-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  backdrop-filter: blur(5px);
}

.success-modal {
  width: 100%;
  max-width: 400px;
  padding: 32px;
  border-radius: 28px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  text-align: center;

  .success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .success-icon {
    width: 64px;
    height: 64px;
    background: linear-gradient(45deg, #30a8bc, #4dda68);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 32px;
    margin-bottom: 8px;
  }

  h2 {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.025em;
    margin: 0;
  }

  p {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    margin: 0;
    opacity: 0.8;
  }

  .close-success-btn {
    margin-top: 24px;
    padding: 12px 32px;
    background-color: #0064ff;
    color: white;
    border: none;
    border-radius: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      background-color: #004fc5;
    }
  }
}
